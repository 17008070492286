/*!
 * Title:   Laapp - HTML App Landing Page
 * Main Javascript file
 * Author:  http://themeforest.net/user/5studiosnet
 * Copyright © 2019 5Studios.net
 * https://5studios.net
 */

// Place any code in here.
import "@fortawesome/fontawesome-free/js/all.js";
import "pixeden-stroke-7-icon/pe-icon-7-stroke/dist/pe-icon-7-stroke.css";
import AOS from "aos";
import "aos/dist/aos.css";
import $ from "jquery";
import "jquery.easing";
import "tilt.js";
import "bootstrap";

// Muestra el botón btn-scroll-to-top al bajar por la página
$(window).on("scroll", function () {
  if ($(this).scrollTop() > 300) {
    $("#btn-scroll-to-top").fadeIn();
  } else {
    $("#btn-scroll-to-top").fadeOut();
  }
});

function ScrollingSpy(settings) {
  this.options = settings;

  var $this = this,
    navbar = $(this.options.navbar);

  this.navbarSticky = function (scrollTop) {
    if (scrollTop >= this.options.offset) {
      navbar.addClass("navbar-sticky");
    } else {
      navbar.removeClass("navbar-sticky");
    }
  };

  this.initialize = function () {
    var w = $(window);

    w.on("scroll", function () {
      var scrollTop = w.scrollTop();
      $this.navbarSticky(scrollTop);
    });

    this.navbarSticky(w.scrollTop());
  };
}

$(function () {
  "use strict";

  /** navbar reference **/
  var $navbar = $(".main-nav");

  var scrollingSpy = new ScrollingSpy({
    offset: 90,
    navbar: ".main-nav",
  });

  scrollingSpy.initialize();

  /**
   * SCROLLING NAVIGATION
   * Enable smooth transition animation when scrolling
   **/
  $("a.scrollto").on("click", function (event) {
    event.preventDefault();

    var scrollAnimationTime = 1200;
    var target = this.hash;

    $("html, body")
      .stop()
      .animate(
        {
          scrollTop: $(target).offset().top - 45,
        },
        scrollAnimationTime,
        "easeInOutExpo",
        function () {
          window.location.hash = target;
        }
      );
  });

  /**
   *  NAVBAR SIDE COLLAPSIBLE - On Mobiles
   **/
  $(".navbar-toggler", $navbar).on("click", function () {
    $navbar.toggleClass("navbar-expanded");
  });

  /* Cierre el menú al pulsar sobre una entrada */
  $(".nav-item", $navbar).on("click", function () {
    $navbar.removeClass("navbar-expanded");
  });

  /**
   * PLUGINS INITIALIZATION
   * Bellow this, you can remove the plugins you're not going to use.
   * If you do so, remember to remove the script reference within the HTML.
   **/

  /**
   * AOS
   * Cool scrolling animations
   **/
  if ($("[data-aos]").length) {
    AOS.init({
      once: true,
      offset: 100,
      duration: 1500,
      disable: "mobile",
    });
  }

  /**
   * TILT
   **/
  if ($(".tilt").length) {
    $(".tilt").tilt({
      glare: true,
      maxGlare: 0.4,
    });
  }
});
